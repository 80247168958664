<template>
    <div>
        <van-dialog
                v-model="dialogShow"
                width="80%"
                :class="isOpen?'qr-dialog':'qr-dialog height'"
                title="考试二维码"
                confirm-button-text="取消"
                @confirm="confirmHandle"
                @open="openHandle"
        >
            <div v-show="isOpen">
                <div class="info-txt">请使用此二维码进行“试卷确认”和“交卷登记”操作。</div>
                <div id="qrcode" ref="qrcode"></div>
                <div class="info">{{code}}</div>
            </div>
            <div v-show="!isOpen">
                {{infoTxt}}
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2';
    import {getQRCode} from "r/index/photoqr";

    export default {
        name: "ViewQrcode",
        props: {
            dialogShowFu: [Boolean],
        },
        data() {
            return {
                dialogShow: false,
                qrUrl: "",
                code: null,
                infoTxt: null,// 提示文字
                isOpen: false,
            }
        },
        methods: {
            openHandle() {
                getQRCode()
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.infoTxt = res.data.data;
                            this.isOpen = false;
                            return false;
                        }
                        this.isOpen = true;
                        let data = res.data.data;
                        this.qrUrl = data;
                        this.code = data.slice(data.length-10, data.length)
                        this.createQrCode()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 生成二维码
            createQrCode() {
                console.log(this.$refs.qrcode)
                this.$refs.qrcode.innerHTML = "";
                new QRCode(this.$refs.qrcode, {
                    text: this.qrUrl, // 需要转换为二维码的内容
                    width: 90,
                    height: 90,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
            confirmHandle() {
                this.dialogShow = false;
                this.$emit('closeDialog', this.dialogShow)
            },
        },
        watch: {
            dialogShowFu() {
                this.dialogShow = this.dialogShowFu;
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .qr-dialog {
        height: 330px;
        display: flex;
        flex-flow: column;

        &.height {
            height: auto;
        }

        .van-dialog__content {
            flex: 1;
            padding: 10px 20px;
            overflow-y: scroll;
            text-align: center;

            .info-txt {
                font-size: 14px;
                font-family: "PingFang SC";
                text-align: left;
            }

            .qr-img {
                width: 100%;
                border: 1px solid #aaaaaa;
                margin-bottom: 10px;
            }

            .info {
                font-size: 14px;
                margin: 15px 0;
            }

            #qrcode {
                margin: 10px auto;
                width: 90px;
                height: 90px;
            }
        }

        .van-dialog__footer {
            height: 48px !important;
        }
    }
</style>
