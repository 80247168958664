<template>
    <div class="monitor-box" ref="monitorBox" :class="horizontal ? 'horizontalMain' : ''">
        <div class="monitor" ref="monitor" :class="horizontal ? 'horizontal' : ''">
            <div class="monitor_header">
                <van-nav-bar :title="title" @click-right="endMonitor" right-text="结束"/>
            </div>
            <div class="monitor_content">
                <p class="monitor_status" v-text="monitor_status"></p>
                <div class="monitor_box" ref="monitor_box">
                    <!-- <video ref="monitor_video" autoplay playsinline-->
                    <!--        :class="isBig?'video_big':'video_small'"-->
                    <!--        @click="changeBigVideo"-->
                    <!-- ></video>-->
                    <!--                 <video ref="audio_video" autoplay playsinline-->
                    <!--                        :class="!isBig?'video_big':'video_small'"-->
                    <!--                        @click="changeBigVideo"-->
                    <!--                 ></video>-->
                    <video ref="audio_video1" autoplay playsinline style="height: 0;width: 0"></video>
                    <video ref="audio_video2" autoplay playsinline style="height: 0;width: 0"></video>
                    <video ref="audio_video3" autoplay playsinline style="height: 0;width: 0"></video>
                    <video ref="audio_video4" autoplay playsinline style="height: 0;width: 0"></video>
                    <video ref="audio_video5" autoplay playsinline style="height: 0;width: 0"></video>
                    <video ref="audio_video6" autoplay playsinline style="height: 0;width: 0"></video>
                    <vue-drag-resize ref="video_top_box" :isActive="true" :isDraggable="!isBig"
                                     :parentLimitation="true"
                                     :parentW="maxWidth" :parentH="maxHeight"
                                     :class="isBig?'video_big':'video_small'"
                                     @clicked="changeBigVideo">
                        <!--学生视频-->
                        <video ref="monitor_video" class="monitor_video" autoplay playsinline></video>
                    </vue-drag-resize>
                    <!--<vue-drag-resize :isActive="true" :isDraggable="isBig"
                                     :parentLimitation="true"
                                     :parentW="maxWidth" :parentH="maxHeight"
                                     :class="!isBig?'video_big':'video_small'"
                                     @dragging="draggingHandle"
                                     @clicked="changeBigVideo">
                        老师视频
                        <video ref="audio_video" autoplay playsinline
                        ></video>
                    </vue-drag-resize>-->

                    <div class="monitor_shuaxi" @click="monitor_shuaxi()">
                        <i class="iconfont iconshuaxin1"></i>
                        <div>刷新</div>
                    </div>
                    <!--          <div class="monitor_horizontal" v-if="canHorizontal" @click="switch_horizontal()">-->
                    <!--            <div v-text="horizontal ? '竖屏' : '横屏'"></div>-->
                    <!--          </div>-->
                    <!--<div class="monitor_fengdai" @click="monitor_fengdai()">
                        <div>试卷袋确认</div>
                    </div>
                    <div class="monitor_kaikao" @click="monitor_kaikao()">
                        <div>试卷确认</div>
                    </div>
                    <div class="monitor_jiaojuan" @click="monitor_jiaojuan()">
                        <div>交卷登记</div>
                    </div>20220508 baiansen-->

                    <div class="monitor_model" ref="monitor_model">
                        <div ref="monitor_model_body">
                            <div class="monitor_model_bubble" v-for="(item,idx) in msgList" :key="idx">
                                <div>
                                    {{item}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="monitor_network_box">
                    网络：<span class="network" :class="'network_'+networkQuality" v-text="networkQualityText"></span>
                    <div style="color: #7fffaa;">{{exam_name}}</div>
                </div>
            </div>
            <div class="monitor_action" :class="is_call ? 'action_call' : ''">
                <div @click="callTeacher">
                    <div>
                        <van-image width="30px" height="30px" :src="iconImg"></van-image>
                    </div>
                    <div>{{call_title}}</div>
                </div>
               <div @click="getQuestion">
                    <div>
                        <van-image width="30px" height="30px" :src="quesImg"></van-image>
                    </div>

                    <div>查看试题</div>
                </div>
                <!--<div @click="getQrcodeHandle">
                    <div>
                        <van-image width="30px" height="30px" :src="qrcodeImg"></van-image>
                    </div>
                    <div>考试二维码</div>
                </div> baiansen 20220508-->
                <!--<div @click="onClickHandle">
                    <div>
                        <van-image width="30px" height="30px" :src="uploadImg"></van-image>
                    </div>
                    <div>上传作品</div>
                </div>-->

            </div>
            <div class="refreshToast" v-if="refreshToast">
                <div>
                    <van-loading/>
                    <div class="refreshToastText" v-if="refreshMonitor > refreshMonitorMax">
                        监控自动重新连接失败
                        <br/>
                        请手动刷新尝试
                        <br/>
                        [注：请检查设备或者网络]
                    </div>
                    <div class="refreshToastText" v-else>正在尝试第<span v-text="refreshMonitor"></span>次重新连接</div>
                </div>
            </div>
        </div>

        <view-ques
                :dialogShowFu="dialogShowFu"
                :objInfoFu="objInfoFu"
                @closeQuesDialog="closeQuesDialog"
        />
        <!--选择开考/交卷登记方式弹框-->
        <select-register-way
                :dialogSRWShowFu="dialogSRWShowFu"
                :typeFu="typeFu"
                @closeSRWDialog="closeSRWDialog"
        />
        <!--条码号输入弹框-->
        <bar-code-input
                :dialogCodeShowFu="dialogCodeShowFu"
                :typeFu="typeFu"
                @closeCodeDialog="closeCodeDialog"
        />
        <!--考试二维码弹框-->
        <view-qrcode
                :dialogShowFu="dialogShowQrFu"
                @closeDialog="closeQrDialog"
        />
        <!--文字提示弹框-->
        <!--20220508 baiansen <van-popup v-model="showTextPopup"
                    class="text-popup"
                    position="bottom"
                    :close-on-click-overlay="false"
                    :style="{ height: '30%' }">
             <div class="txt-content">
                 <div class="txt-box">
                     <div class="txt-item" v-if="textSteps==1">
                         考生站在考试座位旁，面向画板或考试桌面，手持监控设备举于侧上方（避免头部挡住监控画面），顺时针缓慢匀速拍摄一周，画面中须清晰呈现四面墙壁、考试座位所处的地面、天花板、画板（画板不得带有任何图案和文字）。完成后点击【下一步】按钮。
                     </div>
                     <div class="txt-item" v-if="textSteps==2">
                         请在正面监控画面中，展示背面监控除运行“Mirror”监控系统的浏览器或微信以外，并无运行其他应用程序。展示蓝牙为关闭状态。在背面监控画面中，对正面监控进行相同操作。完成此操作后，点击【下一步】按钮。
                     </div>
                     <div class="txt-item" v-if="textSteps==3">
                         请固定正面及背面监控设备。在正面监控画面中，完成：（1）手持身份证停留5秒，需要清晰呈现身份证头像、姓名、身份证号；（2）展示双耳（长发的考生须将头发扎起）无佩戴耳机或其他电子设备，并展示手腕无佩戴手表。完成上述操作后，点击【下一步】按钮。
                     </div>
                       <div class="txt-item" v-if="textSteps==4">
                         请在正面监控画面中，展示当前场次的正式考试试卷袋，须展示试卷袋正面、背面、封条和试卷袋四边，确保试卷袋无损坏。完成此操作后，点击【下一步】按钮。
                     </div>
                 </div>
                 <van-button class="txt-btn" @click="textStepsNext">下一步</van-button>
             </div>
         </van-popup>-->
        <!--交卷结束提醒弹框-->
        <hint-dialog :dialogShowFu="hintDialog"
                     @closeDialog="closeHintDialog"
        ></hint-dialog>
    </div>
</template>


<script>
    import {monitorMixin} from "mixins/index/monitor";
    import BarCodeInput from "../../../components/index/BarCodeInput";
    import HintDialog from "../../../components/index/HintDialog";
    import ViewQrcode from "../../../components/index/ViewQrcode";

    export default {
        name: "monitor",
        components: {ViewQrcode, BarCodeInput, HintDialog},
        mixins: [monitorMixin],
        data() {
            return {
                showTextPopup: false, // 控制文字提示弹框显示与隐藏
                textSteps: 1, // 文字提示步骤
                hintDialog: false,// 控制交卷结束提醒弹框的显示与隐藏
            };
        },
        created() {
            /*let monitorCamera = window.localStorage.getItem('monitor-camera');
            if (monitorCamera === 'front') {
                let showTextPopup = window.localStorage.getItem('monitor-showTextPopup-' + window.localStorage.getItem('monitor-subjectUuId'));
                if (showTextPopup === null || (showTextPopup && showTextPopup === 'true')) {
                    this.showTextPopup = true;
                } else if (showTextPopup && showTextPopup === 'false') {
                    this.showTextPopup = false;
                }
                let textSteps = window.localStorage.getItem('monitor-textSteps-' + window.localStorage.getItem('monitor-subjectUuId'));
                if (textSteps) {
                    this.textSteps = textSteps;
                }
            }*/
            let hintDialog = window.localStorage.getItem('monitor-hintDialog');
            if(hintDialog && hintDialog=='true'){
                this.hintDialog = true;
            }
        },
        methods: {
            // 子组件触发，关闭交卷结束提醒弹框
            closeHintDialog(val){
                this.hintDialog = val;
                window.localStorage.removeItem('monitor-hintDialog');
            },
            // 文字提示下一步
            textStepsNext() {
                this.textSteps++;
                window.localStorage.setItem('monitor-textSteps-' + window.localStorage.getItem('monitor-subjectUuId'), this.textSteps);
                if (this.textSteps == 2) { // if (this.textSteps > 4) {  baiansen 20220508
                    this.showTextPopup = false;
                    window.localStorage.setItem('monitor-showTextPopup-' + window.localStorage.getItem('monitor-subjectUuId'), this.showTextPopup);
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .monitor-box {
        max-width: 1024px;
        margin:0 auto;
        ::v-deep .van-overlay {
            background-color: transparent !important;
        }
    }

    .monitor {
        background: #f8f8f8;

        .monitor_content {
            padding-bottom: 80px;
        }

        .monitor_model {
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            overflow-y: auto;
            padding: 15px;

            .monitor_model_bubble {
                color: rgba(225, 225, 225, 0.9);
                font-size: 12px;
                line-height: 18px;
                margin: 0 0 10px 0;

                > div {
                    max-width: 75%;
                    background-color: rgba(0, 0, 0, 0.15);
                    padding: 6px 10px;
                    border-radius: 16px;
                    display: inline-block;

                    > img {
                        max-width: 100%;
                    }
                }
            }
        }

        .monitor_status {
            padding: 20px 0 10px;
            width: 100%;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333;
            text-align: center;
            position: relative;
        }

        .monitor_box {
            position: relative;
            width: 96%;
            /*min-height: 460px;*/
            /*flex: 1;*/
            height: 500px;
            margin-left: 2%;
            border-radius: 5px;
            box-shadow: 1px 1px 50px 0 rgba(0, 0, 0, 0.1);

            ::v-deep .vdr {
                .vdr-stick {
                    display: none;
                }

                &.active::before {
                    outline: 0;
                }

                &.video_big {
                    position: absolute;
                    left: 0 !important;
                    top: 0 !important;
                    width: 100%;
                    height: 100%;

                    .content-container {
                        height: 100% !important;
                        width: 100% !important;
                    }

                    video {
                        object-fit: fill;
                        border-radius: 5px;
                        height: 100%;
                        width: 100%;
                    }
                }

                &.video_small {
                    width: 80px !important;
                    height: 120px !important;
                    /*float: right !important;*/
                    z-index: 101 !important;

                    .content-container {
                        width: 80px !important;
                        height: 120px !important;
                    }

                    video {
                        object-fit: fill;
                        border-radius: 5px;
                        width: 80px !important;
                        height: 120px !important;
                        background-color: rgba(0, 0, 0, 0.2);
                        z-index: 102 !important;
                    }
                }
            }

            /*video {*/
            /*    object-fit: fill;*/
            /*    border-radius: 5px;*/

            /*    &.video_big {*/
            /*        height: 100%;*/
            /*        width: 100%;*/
            /*    }*/

            /*    &.video_small {*/
            /*        position: absolute;*/
            /*        top: 15px;*/
            /*        right: 15px;*/
            /*        z-index: 101;*/
            /*        width: 80px;*/
            /*        height: 120px;*/
            /*        background-color: rgba(0, 0, 0, 0.2);*/
            /*    }*/
            /*}*/

            .monitor_switch {
                position: absolute;
                top: 10px;
                z-index: 101;
                right: 10px;
                text-align: center;
                padding: 5px;
                border-radius: 5px;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .3);

                &:hover {
                    cursor: pointer;
                }

                .iconfanzhuanjingtou {
                    font-size: 16px;
                }
            }

            .monitor_shuaxi {
                width: 45px;
                height: 45px;
                position: absolute;
                right: 15px;
                top: 45px;
                z-index: 101;
                text-align: center;
                padding: 5px;
                border-radius: 50%;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .3);

                &:hover {
                    cursor: pointer;
                }

                .iconshuaxin1 {
                    font-size: 14px;
                }

                > div {
                    font-size: 12px;
                    margin-top: -3px;
                }
            }


            .monitor_fengdai {
                width: 45px;
                height: 45px;
                position: absolute;
                right: 15px;
                top: 105px;
                z-index: 101;
                text-align: center;
                padding: 5px;
                border-radius: 50%;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .3);

                &:hover {
                    cursor: pointer;
                }

                > div {
                    width: 45px;
                    font-size: 12px;
                    line-height: 16px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .monitor_kaikao {
                width: 45px;
                height: 45px;
                position: absolute;
                right: 15px;
                top: 165px;
                z-index: 101;
                text-align: center;
                padding: 5px;
                border-radius: 50%;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .3);

                &:hover {
                    cursor: pointer;
                }

                > div {
                    width: 32px;
                    font-size: 12px;
                    line-height: 16px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .monitor_jiaojuan {
                width: 45px;
                height: 45px;
                position: absolute;
                right: 15px;
                top: 225px;
                z-index: 101;
                text-align: center;
                padding: 5px;
                border-radius: 50%;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .3);

                &:hover {
                    cursor: pointer;
                }

                > div {
                    width: 32px;
                    font-size: 12px;
                    line-height: 16px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .monitor_horizontal {
                width: 45px;
                height: 45px;
                position: absolute;
                right: 15px;
                top: 200px;
                z-index: 101;
                text-align: center;
                padding: 5px;
                border-radius: 50%;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .3);

                &:hover {
                    cursor: pointer;
                }

                > div {
                    width: 32px;
                    font-size: 12px;
                    line-height: 16px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .monitor_action {
            left: 50%;
            transform: translateX(-50%);

            position: fixed;
            bottom: 0;
            z-index: 101;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            padding: 10px 0;
            display: flex;
            flex-flow: row;
            font-size: 12px;

            > div {
                flex: 1;
                margin: 0 auto;
                text-align: center;

                &.txt-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:hover {
                    cursor: pointer;
                }

            }

            ::v-deep .van-uploader {
                .van-uploader__preview {
                    display: none;
                }
            }

        }
    }

    ::v-deep .van-popup.text-popup {
        padding: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .txt-content {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0 0 54px 0;

            .txt-btn {
                width: 100%;
                color: #ffffff;
                height: 44px;
                border-radius: 8px;
                border-color: #563279;
                background-color: #563279;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }
</style>
<style lang="scss">
    .monitor_network_box {
        position: absolute;
        top: 102px;
        z-index: 100;
        right: 15px;
        color: #fff;
        font-size: 12px;

        .network_0 {
            -webkit-animation: myAnimation 2s infinite;
            animation: myAnimation 2s infinite;
            color: #fff;
        }

        .network_1 {
            color: #7fffaa;
        }

        .network_2, .network_3 {
            color: #90ee90;
        }

        .network_4 {
            color: #ffa500;
        }

        .network_5, .network_6 {
            color: #FF0000;
            -webkit-animation: myAnimation 2s infinite;
            animation: myAnimation 2s infinite;
        }
    }

    @keyframes myAnimation {
        0% {
            opacity: 0;
            filter: alpha(opacity=0);
        }
        100% {
            opacity: 1;
            filter: alpha(opacity=100);
        }
    }

    .horizontalMain {

        height: 100%;

        .van-dialog {
            top: inherit;
            bottom: 0;
            max-width: 320px;
        }

        .subject-dialog-box {
            .van-dialog {
                max-width: inherit;
                top: 73%;
                height: 52% !important;
            }
        }

        .horizontal {
            height: 100%;

            .monitor_action {
                //position: absolute;
            }

            .monitor_content {
                padding-bottom: 20px;
                padding-left: 0;
            }

            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            margin: 0 auto;
            position: relative;

            .monitor_box {
                position: relative;

                .vdr.video_big video {
                    object-fit: inherit;
                }
            }

            .monitor_video {
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                position: absolute;
            }
        }
    }

    .van-dialog__confirm, .van-dialog__confirm:active {
        color: #8b2d87;
    }

    .van-nav-bar__text {
        color: #8b2d87;
    }

    .refreshToast {
        position: absolute;
        width: 100%;
        top: 200px;
        z-index: 999999;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        .refreshToastText {
            margin-top: 10px;
            display: block;
            color: #fff;
        }
    }
</style>
