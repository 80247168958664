//权限相关
import 'aliyun-webrtc-sdk'
import 'audioutil'
import {
    getRtcAuth,
    removeTerminals,
    removeUser,
    refreshToken,
    rtcLog,
    getQuesOss,
    getCommit,
    isNeedShow
} from "r/index/monitor";
import {getSignature} from "r/index/index";
import {studentStatus} from "r/index/studentStatus";


import html2canvas from 'html2canvas'
import iconImg from "a/images/index/js.png";
import quesImg from "a/images/index/ques_img.png";
import uploadImg from "a/images/index/upload_img.png";
import qrcodeImg from "a/images/index/qrcode.png";
import {Toast, Dialog} from "vant";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import light from "muse-ui/es5/theme/light";
import ViewQues from "c/index/ViewQues";
import config from "config/index";
import UploadImg from "c/index/UploadImg";
import SelectRegisterWay from "c/index/SelectRegisterWay";
import BarCodeInput from "c/index/BarCodeInput";
import ViewQrcode from "c/index/ViewQrcode";
import VueDragResize from 'vue-drag-resize';
import {fileUpload} from "r/index/upload";
import {submitTimeOut} from "r/index/submitTimeOut";

export const monitorMixin = {
    components: {
        ViewQues,
        UploadImg,
        SelectRegisterWay,
        BarCodeInput,
        VueDragResize,
        ViewQrcode,
    },
    data: function () {
        return {
            iconImg: iconImg,
            quesImg: quesImg,
            uploadImg: uploadImg,
            qrcodeImg: qrcodeImg,
            exam_name: null,
            controls: true,
            refresh: false,
            timeOut: -1,
            submitTime: null,
            channelId: '',
            camera: '',
            ossConfig: {},
            userid: '',
            user_id: '',
            tuserid: '',
            horizontal: !!localStorage.getItem("horizontal"),
            title: '正面监控',
            userName: '',
            stomp: null,
            socket: null,
            has_stop_socket: true,
            system: '',
            cameraAll: [],
            cameraSelect: 0,
            audioTrackArr: {},
            showCameraSelect: false,
            is_call: 0,// 0-请求通话,1-请求通话中,2-通话连接中,3-通话中
            callArr: {},
            audioVideoArr: [1, 2, 3, 4, 5, 6],
            call_title: "请求通话",
            facingMode: 'user',
            aliWebrtc: {},
            checkFps: false,
            fpsTimes: 0,
            hasRefresh: false,
            checkFpsTime: null,
            monitor_status: '未开启监控',
            networkQuality: 0,
            networkQualityText: "未连接",
            toImageInterval: null,
            subjectUuId: localStorage.getItem("monitor-subjectUuId"),
            timeInterval: null,//时间监听器（用于刷新token）
            logQuery: {
                zkzNum: null,
                message: null
            },
            msgList: [],// 消息列表
            userKey: "",// 获取消息本地缓存的key
            dialogShowFu: false,// 控制题目图片弹框显示与隐藏
            objInfoFu: {
                title: '',
                isSubject: false,
                imgUrl: ''
            },
            isBig: true,// 视频放大缩小 true-学生视频大 false-老师视频大
            maxWidth: 0,
            maxHeight: 0,
            dragTimer: null,
            clickNum: 0,
            canHorizontal: true,
            smallLeft: 0,
            smallTop: 0,
            refreshToast: false,//是否展示刷新的弹框
            refreshMonitor: 0,//当前重新连接次数
            refreshMonitorMax: 40,//系统可以自动重新连接的最大次数
            refreshMonitorChannelMax: 30,//系统自动重连的次数到达阈值的时候需要重新入会
            refreshMonitorTimeout: 0,//自动重连的定时器
            refreshTimeArr: [1, 1, 2, 2, 2, 4, 4, 4, 4, 8, 8, 8, 8, 8, 16, 16, 16, 16, 16, 16, 20],//重新连接的时间间隔，单位秒，最大的是默认是20s
            refreshSocketTimeout: 0,//自动重连的定时器
            refreshSocket: 0,//自动重连的定时器
            dialogSRWShowFu: false,// 控制选择开考/交卷登记方式弹框显示与隐藏
            typeFu: null,// 选择开考/交卷登记方式弹框数据 1-开考 2-交卷
            dialogCodeShowFu: false,// 控制条码号输入弹框显示与隐藏
            testObj: null,
            stuEndTimeFunc: null,
            dialogShowQrFu: false,// 控制考试二维码弹框显示与隐藏
        }
    },
    created() {
        this.refresh = false;
        this.exam_name = window.localStorage.getItem('monitor-examName')
    },
    mounted() {
        let subjectUuId = window.localStorage.getItem('monitor-subjectUuId');
        //qweas4;qweas7;qweaa2;qweaa3
        /*if(!(this.subjectUuId === "qweaa2" || this.subjectUuId === "qweaa3")){
            this.$store.commit("loginOut");
            this.$router.replace('/login');
            return;
        }  //不是当前科目踢出  20220508 baiansen */
        let zkz = window.localStorage.getItem('monitor-zkz');
        this.hengshuping();
        this.onHorizontalChange();
        this.refreshToken();
        // this.getOssConfig();
        let examUuid = window.localStorage.getItem('monitor-examUuid');
        let camera = window.localStorage.getItem('monitor-camera');
        this.userKey = examUuid + '_' + subjectUuId + '_' + zkz + '_' + camera;
        let msgList = window.localStorage.getItem(this.userKey);
        if (msgList) {
            this.msgList = JSON.parse(msgList);
        }
        this.userName = localStorage.getItem("monitor-name");
        this.userid = this.subjectUuId + '_' + zkz + '_' + camera;
        this.user_id = zkz + '_' + camera;
        this.checkCallStatus();
        if (camera) {
            this.camera = camera;
            if (this.camera !== 'back') {
                studentStatus(zkz, subjectUuId).then((res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    if (res.data.renzheng == 0) {
                        this.$router.replace({path: '/camera'});
                        return;
                    } else if (res.data.huanjing == 0) {
                        this.$router.replace({path: '/envir'});
                        return;
                    } else if (res.data.chengnuoshu == 0) {
                        this.$router.replace({path: '/promise'});
                        return;
                    }
                });
            }

            this.aliWebrtc = new AliRtcEngine();
            /**
             * AliWebRTC isSupport检测{isReceiveOnly: false}
             */
            let option = {
                isReceiveOnly: true,
                customPlay: true
            }
            this.aliWebrtc.isSupport(option).then((re) => {
                this.system = re.system;
                this.refreshMonitor = 0;
                let aaa = setTimeout(async () => {
                    aaa && clearTimeout(aaa);
                    //加入房间并推流
                    await this.startMonitor();
                    //监听回调
                    this.onEventRtc();
                }, 100);

            }).catch(error => {
                Toast.fail(error.message);
            });
            //初始化socket
            this.initSocket();
            if (camera === 'back') {
                this.title = '电脑监控';
            } else {
                this.title = '手机监控';
            }
        } else {
            this.$router.replace('/position');
            return;
        }
        this.maxWidth = this.$refs.monitor_box.clientWidth + 105;
        this.maxHeight = this.$refs.monitor_box.clientHeight + 70;
        let aab = setTimeout(() => {
            aab && clearTimeout(aab);
            this.handleScroll();
        }, 500);

        localStorage.setItem('audio_loading', 0);
        //this.horizontalBox(false,false);
        this.setInter(10000);
        // this.$emit("playAudio","video_re");
    },
    methods: {
        // 子组件触发，关闭考试二维码弹框
        closeQrDialog(val) {
            this.dialogShowQrFu = val;
        },
        // 考试二维码
        getQrcodeHandle() {
            this.dialogShowQrFu = true;
        },
        setInter(s) {
            let timeOut = (s) => {
                this.stuEndTimeFunc = setTimeout(() => {
                    this.stuEndTimeFunc && clearTimeout(this.stuEndTimeFunc);
                    this.stuEndTime();
                    timeOut(s);

                }, s)
            }
            timeOut(s);
        },
        //向oss上传图片
        async stuEndTime() {
            let zkz = localStorage.getItem('monitor-zkz');
            let subjectUuId = window.localStorage.getItem('monitor-subjectUuId');
            let res = await submitTimeOut(zkz, subjectUuId);
            if (res.data == -1) {
                res.data = false;
            }
            this.timeOut = res.data;
            if (this.timeOut) {
                //this.toBeSubmit(); //去除mp3时间提示
            }
        },

        toBeSubmit() {
            let subjectUuId = window.localStorage.getItem('monitor-subjectUuId');
            let StartExamLt15 = localStorage.getItem('StartExamLt15' + this.timeOut.sm_subject_uuid);
            let StartExam = localStorage.getItem('StartExam' + this.timeOut.sm_subject_uuid);
            let EndExamLt15 = localStorage.getItem('EndExamLt15' + this.timeOut.sm_subject_uuid);
            let EndExam = localStorage.getItem('EndExam' + this.timeOut.sm_subject_uuid);
            if (this.timeOut.StartExamLt15 <= 0 && this.timeOut.StartExamLt15 > -60 && StartExamLt15 != 1 && this.timeOut.StartExamLt15 != null) {
                if (this.timeOut.subject_audio_num > 0 && this.timeOut.subject_audio_num < 3) {
                    this.$emit("playAudio", "start15_" + this.timeOut.subject_audio_num);
                }
                localStorage.setItem('StartExamLt15' + this.timeOut.sm_subject_uuid, 1);
            } else if (this.timeOut.StartExam <= 0 && this.timeOut.StartExam > -60 && StartExam != 1 && this.timeOut.StartExam != null) {
                this.$emit("playAudio", "start");
                localStorage.setItem('StartExam' + this.timeOut.sm_subject_uuid, 1);
            } else if (this.timeOut.EndExamLt15 <= 0 && this.timeOut.EndExamLt15 > -60 && EndExamLt15 != 1 && this.timeOut.EndExamLt15 != null) {
                if (this.timeOut.subject_audio_num > 0 && this.timeOut.subject_audio_num <= 3) {
                    this.$emit("playAudio", "end15_" + this.timeOut.subject_audio_num);
                }
                localStorage.setItem('EndExamLt15' + this.timeOut.sm_subject_uuid, 1);
            } else if (this.timeOut.EndExam <= 0 && EndExam != 1 && this.timeOut.EndExam != null) {
                if (this.timeOut.subject_audio_num > 0 && this.timeOut.subject_audio_num <= 3) {
                    this.$emit("playAudio", "end_" + this.timeOut.subject_audio_num);
                }
                localStorage.setItem('EndExam' + this.timeOut.sm_subject_uuid, 1);
            }
        },

        // 判断是否能上传作业
        isNeedShow() {
            const zkz = localStorage.getItem('monitor-zkz');
            const subjectUuid = localStorage.getItem("monitor-subjectUuId");
            isNeedShow(subjectUuid, zkz).then(res => {
                if (res.data.code == 200) {
                    this.$router.replace('/upload');
                } else {
                    Toast(res.data.message);
                }
            }).catch(err => {
                console.log(err)
            })
        },

        onHorizontalChange() {
            window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", this.hengshuping, false);
        },
        hengshuping() {
            if (window.orientation == 180 || window.orientation == 0) {
                this.canHorizontal = true;
            }
            if (window.orientation == 90 || window.orientation == -90) {
                this.canHorizontal = false;
                if (this.horizontal) {
                    this.switch_horizontal();
                }
            }
        },
        //截取video图片
        toVideoImage() {
            if (this.camera !== 'back') {
                this.toImageInterval && clearTimeout(this.toImageInterval);
                this.toImageInterval = setTimeout(() => {
                    let localVideo = this.$refs.monitor_video;

                    // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
                    html2canvas(localVideo, {
                        backgroundColor: null,
                        useCORS: true // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
                    }).then(canvas => {
                        canvas.toBlob((blob) => {
                            this.uploadImgToOss(blob);
                        }, "image/jpeg", 0.2);
                    });
                }, 12000)
            }
        },
        //向oss上传图片
        async uploadImgToOss(file) {
            let zkz = localStorage.getItem('monitor-zkz');
            let subjectUuId = window.localStorage.getItem('monitor-subjectUuId');
            let time = new Date().getTime();
            let fileName = subjectUuId + '\/' + zkz + '\/' + zkz + '_' + subjectUuId + '_' + time + ".jpg";
            let uploadData = await fileUpload(this.ossConfig, file, fileName);
            if (uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK') {
            } else {
            }
            this.toVideoImage();
        },
        //获取上传oss的鉴权配置
        async getOssConfig() {
            let expire = localStorage.getItem("oss_pic_config_expire");
            let oss_pic_config = localStorage.getItem("oss_pic_config");
            if (expire && oss_pic_config) {
                let time = parseInt(Math.round(new Date().getTime() / 1000).toString());
                if (expire > time) {
                    this.ossConfig = JSON.parse(oss_pic_config);
                    return true;
                }
            }
            let {data} = await getSignature();
            localStorage.setItem("oss_pic_config_expire", data.expire + "");
            localStorage.setItem("oss_pic_config", JSON.stringify(data));
            this.ossConfig = data;
        },
        //切换横屏
        switch_horizontal() {
            let check = this.horizontalBox(true);
            if (!check) {
                return false;
            }
            this.horizontal = !this.horizontal;
            this.horizontalBox();
        },
        //设置横屏样式
        horizontalBox(is_check = false, is_send = true) {
            let indexMain = setTimeout(() => {
                clearTimeout(indexMain);
                let monitorBox = this.$refs.monitorBox;
                let screenHeight = monitorBox.clientHeight;
                let screenWidth = monitorBox.clientWidth;
                let monitor = this.$refs.monitor;
                let video_top_box = this.$refs.video_top_box;
                let localVideo = this.$refs.monitor_video;
                if (screenWidth > screenHeight) {
                    if (this.horizontal) {
                        localStorage.removeItem("horizontal");
                        monitor && monitor.setAttribute("style", "");
                        video_top_box && localVideo.setAttribute("style", "");
                    }
                    return false;
                }
                if (is_check) {
                    return true;
                }
                let message = {
                    channelId: this.channelId,
                    userid: this.userid,
                    userName: this.userName,
                    type: 2,
                };
                if (this.horizontal) {
                    localStorage.setItem("horizontal", "1");
                    monitor && monitor.setAttribute("style", "width:" + screenHeight + "px;");
                    let maxWidth = this.$refs.video_top_box.$el.clientWidth;
                    let maxHeight = this.$refs.video_top_box.$el.clientHeight;
                    let pix = (maxWidth - maxHeight) / 2;
                    video_top_box && localVideo.setAttribute("style", "width:" + maxHeight + "px;height:" + maxWidth + "px;top: -" + pix + "px;left:" + pix + "px;");
                    message.horizontal = 1;
                } else {
                    message.horizontal = 0;
                    localStorage.removeItem("horizontal");
                    monitor && monitor.setAttribute("style", "");
                    video_top_box && localVideo.setAttribute("style", "");
                }
                let data = {};
                data.message = JSON.stringify(message);
                // data.receiver = '';
                data.subjectUuid = localStorage.getItem('monitor-subjectUuId');
                data = JSON.stringify(data);
                is_send && this.stomp.send('/pubChat', {}, data);
            }, 10);

            return true;
        },
        // 关闭条码号输入弹框触发
        closeCodeDialog(val) {
            this.dialogCodeShowFu = val;
        },
        // 关闭选择开考/交卷登记方式弹框触发
        closeSRWDialog(val, type) {
            this.dialogSRWShowFu = val;
            if (type == 2) {
                this.dialogCodeShowFu = true;
            }
        },
        // 开考/交卷登记前结束监控
        closeMirror() {
            try {
                if (this.has_stop_socket) {
                    this.socket.close();
                }
                this.stuEndTimeFunc && clearTimeout(this.stuEndTimeFunc);
                this.checkFpsTime && clearTimeout(this.checkFpsTime);
                this.checkFps = false;
                this.timeInterval && clearTimeout(this.timeInterval);
                this.stuEndTimeFunc && clearTimeout(this.stuEndTimeFunc);
                this.aliWebrtc && this.aliWebrtc.dispose();
                this.aliWebrtc.muteLocalCamera(true);
                this.aliWebrtc.stopPreview();
            } catch (e) {

            }
        },
        // 试卷袋确认
        monitor_fengdai() {
            this.$router.replace('/qrcode?type=3');
        },
        // 试卷确认
        monitor_kaikao() {
            this.$router.replace('/qrcode?type=1');
        },
        // 交卷登记
        monitor_jiaojuan() {
            //this.$router.replace('/qrcode?type=2')
            this.$router.replace('/upload')
        },
        dragstop(val) {
        },
        draggingHandle(val) {
        },
        changeBigVideo() {
            this.clickNum++;
            if (this.clickNum % 2 == 0 && this.is_call == 3) {
                this.isBig = !this.isBig;
            }
            this.dragTimer = window.setTimeout(() => {
                this.clickNum = 0;
            }, 300)
        },
        // 上传作品按钮点击事件
        onClickHandle() {
            // 判断是否能够上传作品
            // this.isNeedShow();
            this.closeMirror();
            this.$router.push({path: '/upload'});
        },
        // 关闭查看试题弹框触发
        closeQuesDialog(val) {
            this.dialogShowFu = val;
            this.objInfoFu = {
                title: '',
                isSubject: false,
                imgUrl: ''
            }
        },
        // 查看题目-获取题目
        getQuestion() {
            let subjectUuid = window.localStorage.getItem('monitor-subjectUuId');
            if (subjectUuid == 'ceshmy' || subjectUuid == 'minyue') {
                // 民乐-展示老师发的试题
                let imgUrl = window.localStorage.getItem('monitor-questImg-' + subjectUuid);
                if (imgUrl && imgUrl != null && imgUrl != 'null') {
                    this.objInfoFu.imgUrl = imgUrl;
                    this.objInfoFu.title = '查看试题';
                    this.objInfoFu.isSubject = true;
                    this.dialogShowFu = true;
                } else {
                    this.objInfoFu.imgUrl = '';
                    this.objInfoFu.title = '暂无试题';
                    this.objInfoFu.isSubject = true;
                    this.dialogShowFu = true;
                }
                return false;
            }
            let obj = {
                examId: localStorage.getItem("monitor-examUuid"),
                subjectUuid: localStorage.getItem("monitor-subjectUuId"),
                zkzNum: localStorage.getItem("monitor-zkz")
            }
            getQuesOss(obj).then(res => {
                const resData = res.data;
                if (resData.code != 200) {
                    this.objInfoFu.title = resData.message;
                    this.objInfoFu.imgUrl = null;
                    this.objInfoFu.isSubject = false;
                    this.dialogShowFu = true;
                    return;
                }
                this.objInfoFu.imgUrl = resData.data;
                this.objInfoFu.title = resData.message;
                this.objInfoFu.isSubject = true;
                this.dialogShowFu = true;
            }).catch(err => {
                console.log(err);
                Toast.fail(err);
            })
        },
        handleScroll() {
            this.$nextTick(() => {
                let monitor_model = this.$refs['monitor_model'];
                let monitor_model_body = this.$refs['monitor_model_body'];
                let offset = monitor_model_body.offsetHeight - monitor_model.offsetHeight;
                monitor_model.scrollTop = offset + 40;
                if (this.msgList.length >= 3) {
                    window.scrollTo(0, 1000);
                }
            })
        },
        // 打印异常log
        postRtcLog(message) {
            this.logQuery.zkzNum = localStorage.getItem('monitor-zkz');
            this.logQuery.message = message;
            rtcLog(this.logQuery).then(res => {
            }).catch(err => {
            })
        },
        // 刷新token
        refreshToken() {
            let timeKey = 'monitor_timeEnd';
            let nowTime = new Date().getTime();
            let timeEnd = localStorage.getItem(timeKey);
            if (!timeEnd) {
                timeEnd = nowTime;
                localStorage.setItem(timeKey, timeEnd)
            }
            timeEnd = parseInt(timeEnd);
            //每秒定时检查倒计时
            this.timeInterval && clearTimeout(this.timeInterval);
            this.timeInterval = setTimeout(async () => {
                this.timeInterval && clearTimeout(this.timeInterval);
                timeEnd = timeEnd - 1000;
                if (nowTime - timeEnd > 3600000) {
                    let {data: res} = await refreshToken();
                    if (res.code != 200) {
                        localStorage.clear();
                        await this.$router.replace('/login');
                        return;
                    }
                    localStorage.removeItem(timeKey);
                    localStorage.setItem("monitor-token", "Bearer " + res.data);
                }
                this.refreshToken();
            }, 10000);
        },
        //获取Socket用户
        getSocketUser() {
            return {
                'username': this.userid,
                'token': localStorage.getItem("monitor-token"),
                'type': 's'
            };
        },
        //初始化Socket
        initSocket() {
            if (this.refreshSocketTimeout || this.refresh === true) {
                return true;
            }
            if (this.$store.state.socket && this.$store.state.stomp) {
                this.socket = this.$store.state.socket;
                this.stomp = this.$store.state.stomp;
            }
            if (typeof (WebSocket) == "undefined") {
                console.log("您的浏览器不支持WebSocket");
            } else {
                if (!this.socket) {
                    this.socket = new SockJS(config.socket.base_url, null, {timeout: config.socket.timeout});
                }
                if (this.stomp && this.stomp.connected === true && this.socket.readyState === 1) {
                    this.subscribeSocket();
                    return true;
                }
                this.connectSocket();
            }
        },
        subscribeSocket() {
            this.stomp.subscribe("/user/" + this.userid + "/subChat", (res) => {
                let data = JSON.parse(res.body);
                if (data.code == 200) {
                    let message = JSON.parse(data.data.message);
                    let type = message.type;
                    let tuserid = message.teacherId + '_' + message.channelId;
                    if (type == 0) {
                        this.unSubscribeInvigilate(tuserid);
                    } else if (type == 1) {
                        this.is_call = 1;
                        this.subscribeInvigilate(tuserid);
                    } else if (type == 2 || type == 3) {
                        if (message.msg.indexOf('http') !== -1 && message.msg.indexOf('images-paper') !== -1) {
                            let subjectUuid = window.localStorage.getItem('monitor-subjectUuId');
                            this.dialogShowFu = true;
                            this.objInfoFu.imgUrl = message.msg;
                            window.localStorage.setItem('monitor-questImg-' + subjectUuid, message.msg)
                            this.objInfoFu.isSubject = true;
                        } else {
                            if (this.msgList.length > 2) {
                                this.msgList.shift();
                            }
                            this.msgList.push(message.msg);
                        }
                        this.$emit("playAudio", "msg_audio");
                        window.localStorage.setItem(this.userKey, JSON.stringify(this.msgList));
                        this.$nextTick(() => {
                            this.handleScroll();
                            //this.horizontalBox();
                        })
                    } else if (type == 4) {
                        //接受全屏语音
                        let audio_loading = localStorage.getItem('audio_loading');
                        if (audio_loading != 1) {
                            localStorage.setItem("audio_loading", 1);
                            this.playAudio(message.audio);
                            if (this.msgList.length > 2) {
                                this.msgList.shift();
                            }
                            this.msgList.push(message.msg);
                            setTimeout(() => {
                                localStorage.setItem("audio_loading", 0);
                            }, 10000);
                        }

                    }
                }
            });
        },
        playAudio(audio) {
            switch (audio) {
                case 1:
                    this.$emit("playAudio", "start15_1");
                    break;
                case 2:
                    this.$emit("playAudio", "start");
                    break;
                case 3:
                    this.$emit("playAudio", "end15_1");
                    break;
                case 4:
                    this.$emit("playAudio", "end_1");
                    break;
                case 5:
                    this.$emit("playAudio", "start15_2");
                    break;
                case 6:
                    this.$emit("playAudio", "start");
                    break;
                case 7:
                    this.$emit("playAudio", "end15_2");
                    break;
                case 8:
                    this.$emit("playAudio", "end_2");
                    break;
                case 9:
                    this.$emit("playAudio", "start");
                    break;
                case 10:
                    this.$emit("playAudio", "end15_3");
                    break;
                case 11:
                    this.$emit("playAudio", "end_3");
                    break;
            }
        },
        connectSocket() {
            const user = this.getSocketUser();
            //连接
            let stomp = Stomp.over(this.socket);
            stomp.debug = null;
            this.stomp = stomp;
            // 每隔30秒做一次心跳检测
            stomp.heartbeat.outgoing = config.socket.outgoing;
            // 客户端不接收服务器的心跳检测
            stomp.heartbeat.incoming = config.socket.incoming;
            this.stomp = stomp;
            stomp.connect(user, (frame) => {
                //订阅广播
                // stomp.subscribe("topic/chatRoom", function (res) {
                //     console.log("top/chatRoom");
                //     console.log(res.body);
                // });
                // this.refreshSocket = 0;
                // this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                //用户模式
                this.subscribeSocket();
                let arr = {};
                arr.socket = this.socket;
                arr.stomp = this.stomp;
                this.$store.commit("setSocket", arr);
                this.has_stop_socket = false;
            }, () => {
                this.refreshSocket = ++this.refreshSocket;
                let refreshTimeArr = this.refreshTimeArr;
                let time = refreshTimeArr[this.refreshSocket - 1];
                time = time ? time : refreshTimeArr[refreshTimeArr.length - 1];
                this.has_stop_socket = true;
                this.refreshSocketTimeout = setTimeout(async () => {
                    this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                    this.refreshSocketTimeout = null;
                    this.socket = null;
                    this.stomp = null;
                    this.$store.commit("setSocket", null);
                    this.initSocket();
                }, time * 1000);
            });
        },
        //发送Socket消息
        sendMessage(cmd, message, receiver) {
            let data = {
                "message": message
            };
            data.receiver = receiver;
            data.subjectUuid = localStorage.getItem('monitor-subjectUuId');
            data = JSON.stringify(data);
            try {
                this.stomp.send(cmd, {}, data);
                this.is_call = 1;
                this.call_title = "请求通话中";
            } catch (e) {
                this.is_call = 0;
                Toast.fail('发送失败')
            }
        },
        // monitor_switch() {
        //   if(this.cameraAll.length > 0){
        //     let videoArr = this.cameraAll;
        //     if(this.cameraSelect === 0){
        //       this.cameraSelect = 1;
        //     }else {
        //       this.cameraSelect = 0;
        //     }
        //     if(!videoArr[this.cameraSelect]){
        //       this.cameraSelect = 0;
        //     }
        //   }else {
        //     this.facingMode = this.facingMode === "environment" ? "user" : 'environment';
        //   }
        //   if(this.aliWebrtc){
        //     this.aliWebrtc.stopPreview().then(()=>{
        //     }).catch((error) => {
        //       // 结束预览失败
        //     });
        //     this.aliWebrtc = null;
        //   }
        //   this.aliWebrtc = new AliRtcEngine();
        //   // this.startMonitor();
        //   // this.onEventRtc();
        // },
        monitor_shuaxi() {
            if (this.hasRefresh) {
                window.location.reload();
                return;
            }
            this.has_stop_socket = true;
            if (this.socket.readyState == 1) {
                this.has_stop_socket = false;
            }
            this.checkFpsTime && clearTimeout(this.checkFpsTime);
            this.checkFps = false;
            this.$nextTick(() => {
                try {
                    this.aliWebrtc.dispose();
                } catch (e) {
                }
            });
            let key = "generateAliRtcAuthInfo_" + this.userid;
            localStorage.removeItem(key);
            this.$emit("refresh");
        },
        //rtc事件监听
        onEventRtc() {
            /**
             * remote用户加入房间 onJoin
             * 更新在线用户列表
             */
            this.aliWebrtc.on("onJoin", (publisher) => {
                if (this.refresh === true) {
                    return true;
                }
                let userId = publisher.userId;
                let userInfo = this.checkUserId(userId);
                if (userInfo && userInfo.type === 2) {
                    this.aliWebrtc.configRemoteAudio(userId, false);
                    this.aliWebrtc.muteRemoteAudioPlaying(userId, true);
                    this.aliWebrtc.configRemoteCameraTrack(userId, false, false);
                    this.aliWebrtc.setAudioVolume(userId, 1);
                    if (this.horizontal) {
                        let data = {};
                        let message = {
                            channelId: this.channelId,
                            userid: this.userid,
                            userName: this.userName,
                            type: 2,
                            horizontal: 1
                        };
                        data.message = JSON.stringify(message);
                        // data.receiver = '';
                        data.subjectUuid = localStorage.getItem('monitor-subjectUuId');
                        data = JSON.stringify(data);
                        this.stomp.send('/pubChat', {}, data);
                    }
                }
            });
            /**
             * remote流发布事件 onPublish
             * 将该用户新增到推流列表
             * 若该用户已存在推流列表，则进行状态更新
             */
            this.aliWebrtc.on("onPublisher", (publisher) => {
                if (this.refresh === true) {
                    return true;
                }
                let userId = publisher.userId;
                if (this.is_call > 0) {
                    let userInfo = this.checkUserId(userId);
                    if (userInfo && userInfo.type === 2) {
                        this.checkCallStatus();
                    }
                }
                //this.subscribeInvigilate(userId);
            });

            /**
             * remote流结束发布事件 onUnPublisher
             * 推流列表删除该用户
             * 移除用户视图
             * 初始化订阅状态
             */
            this.aliWebrtc.on("onUnPublisher", (publisher) => {
                let userId = publisher.userId;
                let userInfo = this.checkUserId(userId);
                if (userInfo && userInfo.type === 2) {
                    this.unSubscribeInvigilate(userId);
                }
            });

            /**
             * 被服务器踢出或者频道关闭时回调 onBye
             */
            this.aliWebrtc.on("onBye", (message) => {
                if (this.refresh === true) {
                    return true;
                }
                //1:被服务器踢出
                //2:频道关闭
                //3:同一个ID在其他端登录,被服务器踢出
                let msg;
                switch (message.code) {
                    case 1:
                        msg = "被服务器踢出";
                        this.$store.commit("loginOut");
                        this.$router.replace('/login');
                        break;
                    case 2:
                        msg = "频道关闭";
                        break;
                    case 3:
                        msg = "同一个ID在其他端登录,被服务器踢出";
                        break;
                    default:
                        msg = "onBye";
                }
                console.log(message, "onBye");
            });

            /**
             *  错误信息
             */
            this.aliWebrtc.on("onError", (error) => {

                if (this.refresh === true) {
                    return true;
                }
                // Toast.fail('[onError]' + error.errorCode);
                let msg = error && error.message ? error.message : error;
                if (msg && msg.indexOf("no session") > -1) {
                    msg = "请重新登录：" + msg;
                }
                if (error.code == 15) {
                    msg = "没有开启H5兼容";
                }
                if (error.type === "publish") {
                    // 提示用户网络状态不佳
                    if (error.errorCode === 10301 && error.message === "ICE connection failed,please check network or try to stop publish, then publish again") {
                        return true;
                    }
                }
                console.log('onError', error)
                //订阅失败重新订阅老师音频
                if (error.type === "subscribe") {
                    let userId = publisher.userId;
                    this.subscribeInvigilate(userId);
                }
                try {
                    this.postRtcLog(error.message);
                } catch (e) {

                }
                this.monitor_status = '监控失败[正在尝试自动连接]';
                if (error.errorCode === 10009 || error.errorCode === 10002 || error.errorCode === 10042) {
                    this.monitor_status = '开启预览失败[摄像头错误]';
                    Toast.fail("[开启预览失败]摄像头已关闭或者已被占用");
                }
                if (error.errorCode === 10004) {
                    this.monitor_status = '开启预览失败[摄像头错误]';
                    Toast.fail("[开启预览失败]浏览器禁用摄像头");
                }
                if (error.errorCode === 10008 || error.errorCode === 10001) {
                    this.monitor_status = '开启预览失败[麦克风错误]';
                    Toast.fail("[开启预览失败]麦克风已关闭或者已被占用");
                }
                if (error.errorCode === 10003) {
                    this.monitor_status = '开启预览失败[麦克风错误]';
                    Toast.fail("[开启预览失败]浏览器禁用麦克风");
                }
                if (error.errorCode === 10301 ||
                    error.errorCode === 10302 ||
                    error.errorCode === 10310 ||
                    error.errorCode === 10311) {
                    this.monitor_status = '本地预览中[网络错误,正在尝试自动连接]';
                }
                if (error.errorCode === 10303 ||
                    error.errorCode === 10307 ||
                    error.errorCode === 10400 ||
                    error.errorCode === 10401) {
                    this.monitor_status = '加入监控失败[网络错误,正在尝试自动连接]';
                }
                this.leaveChannelHandle();
            });

            /**
             * 检测到用户离开频道
             * 更新用户列表
             * 移除用户视图
             */
            this.aliWebrtc.on("onLeave", (publisher) => {
                //removeUser(this.channelId, publisher.userId);
            });

            this.aliWebrtc.on("onMediaStreamUpdate", async (data) => {
                if (this.refresh === true) {
                    return true;
                }
                let userId = data.userId;
                if (this.is_call > 0) {
                    let userInfo = this.checkUserId(userId);
                    if (userInfo && userInfo.type === 2) {
                        let localVideo = this.$refs.monitor_video;
                        let localMediaStream = localVideo.srcObject
                        if (this.audioTrackArr[userId]) {
                            if (localMediaStream.getTrackById(this.audioTrackArr[userId])) {
                                return true;
                            }
                        }
                        let mediaStream = data.mediaStream;
                        let audioTrack = mediaStream.getAudioTracks()[0];
                        let newAudioTrack = audioTrack.clone();
                        localMediaStream.addTrack(newAudioTrack);
                        this.audioTrackArr[userId] = newAudioTrack.id;
                    }
                }

            });

            this.aliWebrtc.on("onNetworkQuality", (data) => {
                this.networkQuality = data.uplinkNetworkQuality;
                switch (this.networkQuality) {
                    case 1:
                        this.networkQualityText = "极好";
                        break;
                    case 2:
                    case 3:
                        this.networkQualityText = "良好";
                        break;
                    case 4:
                        this.networkQualityText = "不顺畅";
                        break;
                    case 5:
                        this.networkQualityText = "非常差";
                        break;
                    case 6:
                        this.networkQualityText = "断开";
                        break;
                }
            });

            this.aliWebrtc.on("onAudioLevel", (data) => {
                for (let i = 0; i < data.length; i++) {
                    let temp = data[i];
                    if (temp.userId == 0) {
                        this.checkUserAudioLevel(temp.level);
                    }
                }

            });

            this.aliWebrtc.on("onMedia", (data) => {
                if (data.userId === "0" && this.checkFps) {
                    if (data.data.camera) {
                        //判断当前视频流的fps
                        if (data.data.camera.fps === 0 || data.data.bytesSentPerSecond === 0) {
                            ++this.fpsTimes;
                            this.hasRefresh = true;//页面是否是原生刷新
                            let time = localStorage.getItem("monitor_refresh_time");
                            if (time && time > 5) {
                                this.monitor_status = '视频监控失败[请刷新页面尝试]';
                            } else {
                                if (this.fpsTimes > 60) {
                                    time = parseInt(time) + 1;
                                    localStorage.setItem("monitor_refresh_time", time + "");
                                    window.location.reload();//页面原生刷新
                                }
                            }
                            return false;
                        }
                        this.fpsTimes = 0;
                        this.hasRefresh = false;
                    }
                }
            });

        },
        checkUserAudioLevel(level) {
            // console.log(level);
        },
        checkUserId(userId) {
            if (!userId) {
                return false;
            }
            let info = {};
            let userArr = userId.split('_');
            if (userArr.length === 0) {
                return false;
            }
            //学生
            if (userArr.length === 3) {
                info.type = 1;
                let zkz = userId.split('_')[1];
            }
            //监控老师
            if (userArr.length === 2) {
                info.type = 2;
                info.userId = userId;
            }
            return info;
            // if(){
            //
            // }
            //Teacher
        },
        //订阅监考
        subscribeInvigilate(userId) {
            if (this.refresh === true) {
                return true;
            }
            if (!userId) {
                return false;
            }
            let userInfo = this.checkUserId(userId);
            if (!userInfo) {
                return false;
            }
            if (userInfo.type !== 2 || this.is_call === 0) {
                return false;
            }
            let aliWebrtc = this.aliWebrtc;
            aliWebrtc.configRemoteAudio(userId, true);
            aliWebrtc.muteRemoteAudioPlaying(userId, false);
            aliWebrtc.setAudioVolume(userId, 1);
            aliWebrtc.configRemoteCameraTrack(userId, true, config.monitor.subscribeCamera);
            this.call_title = "通话连接中";
            let index = this.audioVideoArr.pop();
            let video = this.$refs["audio_video" + index];
            this.aliWebrtc.subscribe(userId).then(re => {
                this.callArr[userId] = {};
                // this.aliWebrtc.setDisplayRemoteVideo(userId, video, config.monitor.videoType);
                this.callArr[userId].is_call = 2;
                this.callArr[userId].video_index = index;
                this.is_call = 2;
                this.call_title = "通话中";
                this.$forceUpdate();
            }).catch((error) => {
                console.log(error);
                this.callArr[userId] = {};
                this.callArr[userId].is_call = 0;
                this.callArr[userId].video_index = 0;
                this.audioVideoArr.push(index);
                this.checkCallStatus();
                Toast.fail('请求通话失败，请重新请求')
            });
        },
        checkCallStatus() {
            this.is_call = 0;
            this.call_title = "请求通话";
            let callArr = this.callArr;
            for (const call in callArr) {
                if (callArr[call].is_call == 2) {
                    this.is_call = 2;
                    this.call_title = "通话中";
                    break;
                }
            }
        },
        //取消订阅监考
        unSubscribeInvigilate(userId) {
            if (this.callArr[userId] && this.callArr[userId].video_index) {
                this.audioVideoArr.push(this.callArr[userId].video_index);
                this.callArr[userId].is_call = 0;
                this.callArr[userId].video_index = 0;
            }
            this.checkCallStatus();
            let aliWebrtc = this.aliWebrtc;
            aliWebrtc.configRemoteAudio(userId, false);
            aliWebrtc.muteRemoteAudioPlaying(userId, true);
            aliWebrtc.setAudioVolume(userId, 1);
            aliWebrtc.configRemoteCameraTrack(userId, false, config.monitor.subscribeCamera);
            try {
                if (this.audioTrackArr[userId]) {
                    let localVideo = this.$refs.monitor_video;
                    let localMediaStream = localVideo.srcObject;
                    localMediaStream.removeTrack(localMediaStream.getTrackById(this.audioTrackArr[userId]));
                    delete this.audioTrackArr[userId];
                }
            } catch (e) {

            }

            aliWebrtc.subscribe(userId).then(re => {
            }).catch((error) => {
            });
        },
        async setVideoConfig() {
            let videoProfileInfo = config.monitor.videoProfileInfo;
            if (this.horizontal) {
                videoProfileInfo = config.monitor.videoHorizontalProfileInfo;
            }
            if (this.canHorizontal === false) {
                videoProfileInfo = config.monitor.videoHorizontalProfileInfo;
            }
            this.aliWebrtc.setVideoProfile(videoProfileInfo, config.monitor.videoType);
            this.aliWebrtc.videoProfile = videoProfileInfo;
            this.aliWebrtc.enableCamera = true;

            this.aliWebrtc.currentCamera = {
                facingMode: "user"
            };
            if (this.system !== 'iphone') {
                try {
                    let videoArr = [];
                    let devices = await navigator.mediaDevices.enumerateDevices();
                    devices.forEach((device) => {
                        if (device.kind == 'videoinput') {
                            videoArr.push({
                                'label': device.label,
                                'id': device.deviceId
                            })
                        }
                    });
                    this.cameraAll = videoArr;
                    if (videoArr[this.cameraSelect]) {
                        let deviceId = videoArr[this.cameraSelect].id;
                        this.aliWebrtc.currentCamera = {
                            deviceId: deviceId
                        };
                    }
                } catch (e) {
                }
            }
        },
        /**
         * 加入房间
         * 触发：输入房间号、单击加入房间按钮
         * 更新页面信息
         * 默认开启预览
         * 获取鉴权信息
         * 加入房间
         * 本地默认自动推视频流（视频流 + 音频流）
         * 发布本地流
         */
        //开启预览
        async startMonitor() {
            await this.setVideoConfig();
            //1.预览
            let localVideo = this.$refs.monitor_video;
            // this.aliWebrtc.enableHighDefinitionPreview(config.monitor.enableHighDefinitionPreview);
            this.monitor_status = '开启预览中';
            this.aliWebrtc.enableAudioVolumeIndicator = true;
            this.aliWebrtc.startPreview(localVideo).then(async (obj) => {
                //this.toVideoImage();
                this.monitor_status = '本地预览中';
                this.refreshMonitorTimeout && clearTimeout(this.refreshMonitorTimeout);
                this.refreshMonitorTimeout = null;
                await this.joinChannel();
            }).catch((error) => {
                console.log(error, "startPreview");
                this.monitor_status = '开启预览失败';
                // Toast.fail("[开启预览失败]" + error.message);
            });
        },
        async joinChannel(has_joinChannel = true, startPreview = false) {
            if (this.refresh === true || this.refreshMonitorTimeout === true) {
                return true;
            }
            this.checkFps = false;
            //2. 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
            let authInfo = await this.generateAliRtcAuthInfo();
            let userName = localStorage.getItem('monitor-name');

            //是否需要入会
            if (has_joinChannel) {
                this.aliWebrtc.joinChannel(authInfo, userName).then(() => {
                    this.monitor_status = '加入监控中';
                    this.publishUserVideo(!has_joinChannel, startPreview);
                }).catch(async (error) => {
                    this.refreshMonitor = ++this.refreshMonitor;
                    if (this.refreshMonitor > 0) {
                        this.refreshToast = true;
                    }
                    if (this.refreshMonitor === 1) {
                        //this.$emit("playAudio","video_re");
                    }
                    if (this.refreshMonitor > this.refreshMonitorMax) {
                        this.monitor_status = '重新加入监控失败';
                        return false;
                    }
                    console.log(error, "joinChannel");
                    Toast.fail("[加入监控失败]" + error.message);
                    this.monitor_status = '加入监控失败';
                    let refreshTimeArr = this.refreshTimeArr;
                    let time = refreshTimeArr[this.refreshMonitor - 1];
                    time = time ? time : refreshTimeArr[refreshTimeArr.length - 1];
                    this.refreshMonitorTimeout = setTimeout(async () => {
                        this.refreshMonitorTimeout && clearTimeout(this.refreshMonitorTimeout);
                        this.refreshMonitorTimeout = null;
                        await this.joinChannel(has_joinChannel, startPreview);
                    }, time * 1000);
                })
            } else {
                this.monitor_status = '加入监控中';
                this.publishUserVideo(!has_joinChannel, startPreview);
            }
        },
        publishUserVideo(has_joinChannel = true, startPreview = false) {
            if (this.refresh === true || this.refreshMonitorTimeout === true) {
                return true;
            }
            // 4. 发布本地流
            this.aliWebrtc.configLocalAudioPublish = true;
            this.aliWebrtc.configLocalCameraPublish = true;
            this.aliWebrtc.publish().then((res) => {
                this.monitor_status = '监控成功';
                //this.$emit("playAudio","video_success");
                this.refreshToast = false;
                this.refreshMonitor = 0;
                this.refreshMonitorTimeout && clearTimeout(this.refreshMonitorTimeout);
                this.refreshMonitorTimeout = null;
                this.checkFpsTime = setTimeout(() => {
                    this.checkFpsTime && clearTimeout(this.checkFpsTime);
                    this.checkFps = true;
                }, 10000);
                this.hasRefresh = false;
                localStorage.removeItem("monitor_refresh_time");
            }).catch(async (error) => {
                this.refreshMonitor = ++this.refreshMonitor;
                if (this.refreshMonitor > 0) {
                    this.refreshToast = true;
                }
                if (this.refreshMonitor === 1) {
                    //this.$emit("playAudio","video_re");
                }
                if (this.refreshMonitor > this.refreshMonitorMax) {
                    this.monitor_status = '重新加入监控失败';
                    return false;
                }
                if (error.code === 33620230) {
                    this.monitor_status = '监控成功';
                    //this.$emit("playAudio","video_success");
                    this.refreshToast = false;
                    this.refreshMonitor = 0;
                    this.refreshMonitorTimeout && clearTimeout(this.refreshMonitorTimeout);
                    this.refreshMonitorTimeout = null;
                    return true;
                }
                console.log(error, "publish");
                let refreshTimeArr = this.refreshTimeArr;
                let time = refreshTimeArr[this.refreshMonitor - 1];
                time = time ? time : refreshTimeArr[refreshTimeArr.length - 1];
                if (startPreview) {
                    let localVideo = this.$refs.monitor_video;
                    this.monitor_status = '开启预览中';
                    this.aliWebrtc.startPreview(localVideo).then(async (obj) => {
                        this.monitor_status = '本地预览中';
                        await this.publishUserVideo(has_joinChannel);
                    }).catch(async (error) => {
                        console.log(error, "startPreview");
                        this.monitor_status = '开启预览失败';
                        Toast.fail("[开启预览失败]" + error.message);
                        this.refreshMonitorTimeout = setTimeout(async () => {
                            this.refreshMonitorTimeout && clearTimeout(this.refreshMonitorTimeout);
                            this.refreshMonitorTimeout = null;
                            if (this.refreshMonitor > this.refreshMonitorChannelMax) {
                                this.hasRefresh = true;
                                this.refresh = true;
                                await this.leaveChannelHandle();
                            } else {
                                await this.publishUserVideo(has_joinChannel, true);
                            }
                        }, time * 1000);
                    });
                } else {
                    this.refreshMonitorTimeout = setTimeout(async () => {
                        this.refreshMonitorTimeout && clearTimeout(this.refreshMonitorTimeout);
                        this.refreshMonitorTimeout = null;
                        if (this.refreshMonitor > this.refreshMonitorChannelMax) {
                            this.hasRefresh = true;
                            this.refresh = true;
                            await this.leaveChannelHandle();
                        } else {
                            await this.publishUserVideo(has_joinChannel);
                        }
                    }, time * 1000);
                }
                // Toast.fail("监控失败");
                // Toast.fail("监控失败"+error.message);
                this.monitor_status = '监控失败';
            });
        },
        // 离开重连
        async leaveChannelHandle() {
            //await this.setVideoConfig();
            let aa = setTimeout(async () => {
                aa && clearTimeout(aa);
                await this.joinChannel(true, true);
            }, 500);
            this.aliWebrtc.leaveChannel().then(async () => {
            }).catch(async (err) => {
            });
        },
        //获取rtc的鉴权
        async generateAliRtcAuthInfo() {
            let key = "generateAliRtcAuthInfo_" + this.userid;
            let authInfo = localStorage.getItem(key);
            if (authInfo) {
                authInfo = JSON.parse(authInfo);
                this.channelId = authInfo.channel;
                let now_time = Math.round(new Date().getTime() / 1000).toString();
                if (authInfo.timestamp > now_time) {
                    return authInfo;
                }
            }
            let {data} = await getRtcAuth();
            if (data.code == 200) {
                data = data.data;
                let channelId = data.channelId;
                this.channelId = channelId;
                this.user_id = data.userid;
                let authInfo = {
                    appid: data.appid,
                    userid: data.userid,
                    timestamp: data.timestamp,
                    nonce: data.nonce,
                    token: data.token,
                    gslb: data.gslb,
                    channel: channelId
                };
                localStorage.setItem(key, JSON.stringify(authInfo));
                return authInfo;
            }
        },
        async endMonitor() {
            Dialog.confirm({
                title: '提醒',
                message: '是否结束监控？',
            }).then(() => {
                Toast('结束成功');
                let key = "generateAliRtcAuthInfo_" + this.userid;
                localStorage.removeItem(key);
                this.$router.replace('/position');
            }).catch(() => {
                // on cancel
            });

        },
        //向老师发起通话请求
        callTeacher() {
            if (this.is_call == 0) {
                let message = {
                    channelId: this.channelId,
                    userid: this.userid,
                    userName: this.userName,
                    type: 1,
                };
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message);
            }
        }
    },
    // 页面注销时候调用 避免连接错误
    destroyed() {
        this.closeMirror();
        this.refresh = true;
    },
};
