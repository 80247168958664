<template>
    <div>
        <van-dialog
                v-model="dialogShow"
                width="100%"
                class="ques-dialog"
                title=""
                @confirm="confirmHandle"
                @cancel="cancelHandle"
                showCancelButton
                confirmButtonText="确认上传"
                cancelButtonText="取消重拍"
        >
            <van-uploader
                    ref="uploadImg"
                    v-model="fileList"
                    multiple
                    :before-read="beforeReadAnswer"
                    :after-read="afterReadAnswer"
                    :preview-full-image="false"
                    capture="camera"
                    accept="image/*"
            >
            </van-uploader>
        </van-dialog>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import {getSignature, submitAnswer} from "r/index/index";
    import {fileUpload} from "r/index/upload";
    import {getCommit} from "r/index/monitor";


    export default {
        name: "UploadImg",
        props: {
            dialogUIShowFu: [Boolean],
            imgInfoFu: [Object,String]
        },
        data() {
            return {
                dialogShow: false,
                objInfo: this.imgInfoFu,
                fileList: [],
            }
        },
        methods: {
            // 确认
            confirmHandle(){
                this.getCommitStatus();
            },
            // 查看作品提交状态
            getCommitStatus() {
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                let obj = {
                    zkzNum: zkz,
                    subjectUuid: subjectUuid
                }
                getCommit(obj).then(res => {
                    let resData = res.data;
                    if (resData.code != 200) {
                        return;
                    }
                    if (resData.result === 'true') {
                        this.uploadHandle(this.objInfo);
                    } else {
                        this.$emit('closeUIDialog', false, 1);
                        Dialog.alert({
                            message: '作品已上传，不能重复上传',
                        }).then(() => {
                        });
                    }
                }).catch(err => {
                    console.log(err);
                })
            },
            // 取消
            cancelHandle(){
                this.objInfo = {};
                this.$emit('closeUIDialog', false, 0)
            },
            async uploadHandle(file){
                file.status = 'uploading';
                file.message = '上传中...';
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                const fileName = 'answer_' + zkz + '_' + subjectUuid + '.jpg';

                let signatureOss = window.localStorage.getItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'));
                if (signatureOss && signatureOss !== '{}' && Object.keys(JSON.parse(signatureOss)).length > 0) {
                    const data = JSON.parse(signatureOss);
                    await this.uploadRequest(data, file, fileName)
                } else {
                    const {data} = await getSignature();
                    window.localStorage.setItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'), JSON.stringify(data));
                    await this.uploadRequest(data, file, fileName)
                }
                // const {data} = await getSignature();
                // const uploadData = await fileUpload(data, file.file, fileName);
                // if (uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK') {
                //     file.status = 'success';
                //     file.message = '上传成功';
                //     file.fileName = fileName;
                //     Toast.success('上传成功');
                //     this.$emit('closeUIDialog', false, 1);
                // } else {
                //     file.status = 'failed';
                //     file.message = '上传失败';
                //     Toast.fail('上传失败');
                // }
            },
            // 上传请求
            async uploadRequest(data, file, fileName){
                const uploadData = await fileUpload(data, file.file, fileName);
                if (uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK') {
                    file.status = 'success';
                    file.message = '上传成功';
                    file.fileName = fileName;
                    Toast.success('上传成功');
                    this.$emit('closeUIDialog', false, 1);
                } else {
                    file.status = 'failed';
                    file.message = '上传失败';
                    Toast.fail('上传失败');
                }
            },
            // 上传作品-读取文件
            beforeReadAnswer(file){
                return file;
            },
            // 上传作品
            afterReadAnswer(file) {
                this.dialogUIShowFu = true;
                this.imgInfoFu = file;
            },

        },
        watch: {
            dialogUIShowFu() {
                this.dialogShow = this.dialogUIShowFu;
            },
            imgInfoFu(){
                this.objInfo = this.imgInfoFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .ques-dialog{
        max-height: 80%;
        display: flex;
        flex-flow: column;
        .van-dialog__content{
            flex: 1;
            padding: 10px;
            overflow-y: scroll;
            text-align: center;
            .ques-img{
                width: 100%;
            }
            .info{
                font-size: 18px;
                margin: 15px 0;
            }
        }
        .van-dialog__footer{
            height: 48px !important;
        }
    }
</style>

