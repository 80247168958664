<template>
    <div>
        <van-dialog
                v-model="dialogShow"
                width="100%"
                title="条码号"
                class="select-dialog"
                @confirm="confirmHandle"
                @cancel="cancelHandle"
                :before-close="beforeClose"
                showCancelButton
                confirmButtonText="确认"
                cancelButtonText="取消"
        >
            <van-icon class="close-icon" name="cross" @click="cancelHandle"/>
            <van-field
                    v-model="barCode"
                    name="barCode"
                    :border="false"
                    :error="barCodeError"
                    :error-message="barCodeErrorMsg"
                    @blur="barCodeCheck"
                    placeholder="请填写条码号"
            />
        </van-dialog>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import {authCode, handInThePaper} from "r/index/monitor";

    export default {
        name: "BarCodeInput",
        props: {
            dialogCodeShowFu: [Boolean],
            typeFu: [Number, String, Object]
        },
        data() {
            return {
                dialogShow: false,
                type: this.typeFu,
                barCode: '',
                barCodeError: '',
                barCodeErrorMsg: '',
            }
        },
        methods: {
            barCodeCheck() {
                if (this.barCode == "" || this.barCode.length <= 0) {
                    this.barCodeError = true;
                    this.barCodeErrorMsg = "请输入条码号";
                } else {
                    this.barCodeError = false;
                    this.barCodeErrorMsg = "";
                }
            },
            beforeClose(action, done){
                if(action == 'confirm'){
                    return done(false);
                }
                done();
            },
            // 确认
            confirmHandle() {
                if (this.barCodeError || this.barCode === '') {
                    this.barCodeCheck();
                    return false;
                }
                if(this.type==1){
                    this.requestHandle(authCode)
                }else if(type==2){
                    this.requestHandle(handInThePaper)
                }
            },
            async requestHandle(reqName){
                let obj = {
                    subjectUuid: window.localStorage.getItem('monitor-subjectUuId'),
                    zkzNum: window.localStorage.getItem('monitor-zkz'),
                    code: this.barCode
                }
                let {data: res} = await reqName(obj);
                console.log(res)
                if (res.code != 200) {
                    return Toast(res.result);
                }
                Toast(res.result);
                this.dialogShow = false;
                this.$emit('closeCodeDialog', this.dialogShow);
            },
            // 取消
            cancelHandle() {
                this.barCode = '';
                this.barCodeError = false;
                this.barCodeErrorMsg = "";
                this.dialogShow = false;
                this.$emit('closeCodeDialog', this.dialogShow);
            },
        },
        watch: {
            dialogCodeShowFu() {
                this.dialogShow = this.dialogCodeShowFu;
            },
            typeFu() {
                this.type = this.typeFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .select-dialog {
        max-height: 80%;
        display: flex;
        flex-flow: column;

        .van-dialog__content {
            flex: 1;
            padding: 15px 15px 20px 15px;
            overflow-y: scroll;
            text-align: left;

            .close-icon {
                position: absolute;
                right: 15px;
                top: 26px;
                color: #333333;
            }

            .van-field,
            .van-field--error{
                padding: 0;
                height: 70px;

                .van-field__body{
                    padding: 1px 0;
                }
                .van-field__control {
                    width: 100%;
                    font-size: 14px;
                    border: 1px solid #aaaaaa;
                    border-radius: 5px;
                    padding: 5px 8px;

                    &::placeholder {
                        color: #969799;
                    }


                    &:focus {
                        border-color: #563279;
                    }

                    &:disabled {
                        color: #323233;
                    }
                }
            }
        }

        .van-dialog__footer {
            height: 48px !important;
        }
    }
</style>


