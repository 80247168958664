<template>
    <div>
        <van-dialog
                v-model="dialogShow"
                width="100%"
                title="提示"
                class="select-dialog"
                @confirm="confirmHandle"
                @cancel="cancelHandle"
                showCancelButton
                confirmButtonText="扫码识别"
                cancelButtonText="条码号输入"
        >
            <van-icon class="close-icon" name="cross" @click="cancelBtn"/>
            <div class="info">请选择以下任意一种方式进行开考登记。</div>
            <div class="info">“扫码识别”方式可直接对画纸中条码进行拍照进行自动登记。</div>
            <div class="info">“条码号输入”方式需输入条码上的数字进行登记。</div>
            <div class="info">若登记成功，提示用户“开考登记成功！”，否则提示“登记失败。请更换登记方式或检查条码号是否输入正确。</div>
        </van-dialog>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";

    export default {
        name: "SelectRegisterWay",
        props: {
            dialogSRWShowFu: [Boolean],
            typeFu: [Number, String, Object]
        },
        data() {
            return {
                dialogShow: false,
                type: this.typeFu,
            }
        },
        methods: {
            // 取消
            cancelBtn() {
                this.dialogShow = false;
                this.$emit('closeSRWDialog', this.dialogShow, 0);
            },
            // 扫码识别
            confirmHandle() {
                this.dialogShow = false;
                this.$emit('closeSRWDialog', this.dialogShow, 1);
            },
            // 条码号输入
            cancelHandle() {
                this.dialogShow = false;
                this.$emit('closeSRWDialog', this.dialogShow, 2);
            },
        },
        watch: {
            dialogSRWShowFu() {
                this.dialogShow = this.dialogSRWShowFu;
            },
            typeFu() {
                this.type = this.typeFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .select-dialog {
        max-height: 80%;
        display: flex;
        flex-flow: column;

        .van-dialog__content {
            flex: 1;
            padding: 15px;
            overflow-y: scroll;
            text-align: left;

            .close-icon {
                position: absolute;
                right: 15px;
                top: 26px;
                color: #333333;
            }

            .info {
                font-size: 14px;
                margin: 5px 0;
            }
        }

        .van-dialog__footer {
            height: 48px !important;
        }
    }
</style>

