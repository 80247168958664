<template>
  <div :class="isSubject ? 'subject-dialog-box' : ''">
    <van-dialog
      v-model="dialogShow"
      width="100%"
      class="ques-dialog"
      title=""
      @confirm="confirmHandle"
      @open="openHandle"
      :style="{
        height: height,
      }"
    >
      <div v-if="imgList.length > 0">
        <img
          v-for="(item, index) in imgList"
          :key="index"
          :src="item"
          class="ques-img"
        />
      </div>

      <div v-else class="info">{{ objInfo.title }}</div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "ViewQues",
  props: {
    dialogShowFu: [Boolean],
    objInfoFu: [Object, Boolean, String],
  },
  data() {
    return {
      dialogShow: false,
      isSubject: false,
      objInfo: this.objInfoFu,
      imgList: [],
      //height: '130px'
      //height: '900px'
      height: "130px",
    };
  },
  methods: {
    openHandle() {
      this.objInfo = this.objInfoFu;
      if (this.objInfo.imgUrl) {
        this.imgList = this.objInfo.imgUrl.split(",");
        //this.height = '80%';
        let camera = window.localStorage.getItem("monitor-camera");
        console.log("this.camera_______" + camera);
        if (camera == "front") {
          this.height = "80%";
        } else {
          //   this.height = '850px';
          this.height = "80%";
        }
      }
      this.isSubject = false;
      if (this.objInfo.isSubject) {
        this.isSubject = true;
      }
    },
    confirmHandle() {
      this.$emit("closeQuesDialog", false);
    },
  },
  watch: {
    dialogShowFu() {
      this.dialogShow = this.dialogShowFu;
    },
    objInfoFu() {
      this.objInfo = this.objInfoFu;
      if (this.objInfo.imgUrl) {
        this.imgList = this.objInfo.imgUrl.split(",");
        this.height = "80%";
      }
      this.isSubject = false;
      if (this.objInfo.isSubject) {
        this.isSubject = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ques-dialog {
  height: 80%;
  display: flex;
  flex-flow: column;

  .van-dialog__content {
    flex: 1;
    // padding: 10px;
    // overflow-y: scroll;
    text-align: center;

    .ques-img {
      width: auto;
      border: 1px solid #aaaaaa;
      margin-bottom: 10px;
      height: auto;
      max-height: 760px;
      max-width: 100%;
    }

    .info {
      font-size: 18px;
      margin: 15px 0;
    }
  }

  .van-dialog__footer {
    height: 48px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .van-dialog__content {
    flex: 1;
    // overflow-y: scroll;
    text-align: center;
    .ques-img {
      width: auto;
      border: 1px solid #aaaaaa;
      margin-bottom: 10px;
       height: auto;
      max-height: 411px !important;
      max-width: 100%;
    }

    .info {
      font-size: 18px;
      margin: 15px 0;
    }
  }

  .van-dialog__footer {
    height: 48px !important;
  }
}
</style>
