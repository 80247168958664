const config = {
    socket:{
        base_url : "https://socket-qhf.thussat.net/xechat",
        timeout : 2000,
        outgoing : 5000,
        incoming : 1
    },
    monitor:{
        enableHighDefinitionPreview:true,//设置是否开启高清预览
        subscribeCamera:false,//是否订阅老师的视频流
        videoType:1,
        videoProfileInfo : {
            frameRate:15,
            width: 1280,
            height: 720,
            maxBitrate: 500000
        },
        videoHorizontalProfileInfo : {
            frameRate:15,
            width: 1280,
            height: 720,
            maxBitrate: 500000
        }
    }
};
export default config
