import { render, staticRenderFns } from "./SelectRegisterWay.vue?vue&type=template&id=78a3ead6&scoped=true&"
import script from "./SelectRegisterWay.vue?vue&type=script&lang=js&"
export * from "./SelectRegisterWay.vue?vue&type=script&lang=js&"
import style0 from "./SelectRegisterWay.vue?vue&type=style&index=0&id=78a3ead6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78a3ead6",
  null
  
)

export default component.exports