<template>
    <van-dialog
            v-model="dialogShow"
            width="100%"
            class="upload-sample"
            title="提醒"
            :showConfirmButton="false"
            :showCancelButton="false"
    >
        <div class="hint-txt">请勿退出视频监控。待监考老师确认后方可退出。</div>
        <div class="hint-btn">
            <van-button type="primary" class="confirm" @click="onConfirm">我知道了</van-button>
        </div>
    </van-dialog>
</template>

<script>
    export default {
        name: "HintDialog",
        props: {
            dialogShowFu: [Boolean],
        },
        data() {
            return {
                dialogShow: this.dialogShowFu,
            }
        },
        methods: {
            // 拍照上传
            onConfirm() {
                this.dialogShow = false;
                this.$emit('closeDialog', this.dialogShow);
            },
        },
        watch: {
            dialogShowFu() {
                this.dialogShow = this.dialogShowFu;
            },
        }
    }
</script>

<style scoped lang="scss">
    .upload-sample {
        width: 80% !important;
        max-width: 400px;
        max-height: 85%;
        display: flex;
        flex-flow: column;
        ::v-deep .van-dialog__header{
            font-size: 16px;
            font-family: "PingFang SC";
        }

        ::v-deep .van-dialog__content {
            flex: 1;
            padding: 25px 20px 86px;
            text-align: center;
            font-family: "PingFang SC";

            .hint-txt {
                text-align: left;
                font-size: 16px;
                line-height: 24px;
                margin: 0 0 10px 0;
            }

            .hint-btn {
                background: rgba(255, 255, 255, 0.9);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 0 25px 20px;

                .confirm {
                    width: 100%;
                    border-radius: 8px;
                    font-size: 14px;
                    color: #ffffff;
                    border-color: #563279;
                    background-color: #563279;

                    .van-uploader__input-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }
</style>


